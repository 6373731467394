// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clients-[restorer]-restorer-js": () => import("./../../../src/pages/clients/restorer/[restorer].js" /* webpackChunkName: "component---src-pages-clients-[restorer]-restorer-js" */),
  "component---src-pages-clients-account-js": () => import("./../../../src/pages/clients/account.js" /* webpackChunkName: "component---src-pages-clients-account-js" */),
  "component---src-pages-clients-all-restorer-js": () => import("./../../../src/pages/clients/allRestorer.js" /* webpackChunkName: "component---src-pages-clients-all-restorer-js" */),
  "component---src-pages-clients-dashboard-[id]-js": () => import("./../../../src/pages/clients/dashboard/[id].js" /* webpackChunkName: "component---src-pages-clients-dashboard-[id]-js" */),
  "component---src-pages-clients-gallery-[id]-js": () => import("./../../../src/pages/clients/gallery/[id].js" /* webpackChunkName: "component---src-pages-clients-gallery-[id]-js" */),
  "component---src-pages-clients-invoices-[id]-js": () => import("./../../../src/pages/clients/invoices/[id].js" /* webpackChunkName: "component---src-pages-clients-invoices-[id]-js" */),
  "component---src-pages-clients-overview-[id]-js": () => import("./../../../src/pages/clients/overview/[id].js" /* webpackChunkName: "component---src-pages-clients-overview-[id]-js" */),
  "component---src-pages-clients-post-js": () => import("./../../../src/pages/clients/post.js" /* webpackChunkName: "component---src-pages-clients-post-js" */),
  "component---src-pages-clients-quotes-[id]-js": () => import("./../../../src/pages/clients/quotes/[id].js" /* webpackChunkName: "component---src-pages-clients-quotes-[id]-js" */),
  "component---src-pages-clients-restorer-js": () => import("./../../../src/pages/clients/restorer.js" /* webpackChunkName: "component---src-pages-clients-restorer-js" */),
  "component---src-pages-clients-welcome-js": () => import("./../../../src/pages/clients/welcome.js" /* webpackChunkName: "component---src-pages-clients-welcome-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-forgotten-js": () => import("./../../../src/pages/login/forgotten.js" /* webpackChunkName: "component---src-pages-login-forgotten-js" */),
  "component---src-pages-login-restorers-js": () => import("./../../../src/pages/login/restorers.js" /* webpackChunkName: "component---src-pages-login-restorers-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-restorers-account-js": () => import("./../../../src/pages/restorers/account.js" /* webpackChunkName: "component---src-pages-restorers-account-js" */),
  "component---src-pages-restorers-add-js": () => import("./../../../src/pages/restorers/add.js" /* webpackChunkName: "component---src-pages-restorers-add-js" */),
  "component---src-pages-restorers-dashboard-[id]-js": () => import("./../../../src/pages/restorers/dashboard/[id].js" /* webpackChunkName: "component---src-pages-restorers-dashboard-[id]-js" */),
  "component---src-pages-restorers-edit-[id]-js": () => import("./../../../src/pages/restorers/edit/[id].js" /* webpackChunkName: "component---src-pages-restorers-edit-[id]-js" */),
  "component---src-pages-restorers-gallery-[id]-js": () => import("./../../../src/pages/restorers/gallery/[id].js" /* webpackChunkName: "component---src-pages-restorers-gallery-[id]-js" */),
  "component---src-pages-restorers-index-js": () => import("./../../../src/pages/restorers/index.js" /* webpackChunkName: "component---src-pages-restorers-index-js" */),
  "component---src-pages-restorers-invoices-[id]-js": () => import("./../../../src/pages/restorers/invoices/[id].js" /* webpackChunkName: "component---src-pages-restorers-invoices-[id]-js" */),
  "component---src-pages-restorers-quotes-[id]-js": () => import("./../../../src/pages/restorers/quotes/[id].js" /* webpackChunkName: "component---src-pages-restorers-quotes-[id]-js" */),
  "component---src-pages-restorers-upload-[id]-js": () => import("./../../../src/pages/restorers/upload/[id].js" /* webpackChunkName: "component---src-pages-restorers-upload-[id]-js" */),
  "component---src-pages-restorers-welcome-js": () => import("./../../../src/pages/restorers/welcome.js" /* webpackChunkName: "component---src-pages-restorers-welcome-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */)
}

