const breakpoints = ['320px', '480px', '576px', '767px', '992px', '1200px', '1440px', '1890px']

breakpoints.xxxs = breakpoints[0]
breakpoints.xxs = breakpoints[1]
breakpoints.xs = breakpoints[2]
breakpoints.sm = breakpoints[3]
breakpoints.md = breakpoints[4]
breakpoints.lg = breakpoints[5]
breakpoints.xl = breakpoints[6]
breakpoints.xxl = breakpoints[6]
breakpoints.xxxl = breakpoints[7]

export default breakpoints
