const text = {
    paragraph: {
        color: 'grey',
        fontFamily: 'body',
        fontWeight: 'regular',
        fontSize: 'body',
        lineHeight: '19px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    h1: {
        color: 'grey',
        fontWeight: 'semibold',
        fontFamily: 'heading',
        letterSpacing: '2px',
        lineHeight: '45px',
        fontSize: 'h1',
        '@media screen and (max-width: 576px)': {
            fontSize: '36px',
            lineHeight: '43px',
        },
    },
    h2: {
        color: 'grey',
        fontFamily: 'heading',
        fontSize: 'h2',
        fontWeight: 'semibold',
        letterSpacing: '4px',
        lineHeight: '30px',
        marginBottom: '10px',
        '@media screen and (max-width: 576px)': {
            fontSize: '21px',
            lineHeight: '30px',
        },
    },
    h3: {
        color: 'grey',
        fontFamily: 'heading',
        fontWeight: 'semibold',
        letterSpacing: '2px',
        lineHeight: '45px',
        fontSize: 'h3',
    },
    h4: {
        color: 'grey',
        fontFamily: 'heading',
        fontWeight: 'semibold',
        letterSpacing: '4px',
        lineHeight: '24px',
        fontSize: 'h4',
    },
    h5: {
        color: 'grey',
        fontFamily: 'heading',
        fontWeight: 'semibold',
        letterSpacing: '1px',
        lineHeight: '28x',
        fontSize: 'h5',
    },
    h6: {
        color: 'grey',
        fontFamily: 'heading',
        fontWeight: 'semibold',
        letterSpacing: '3px',
        fontSize: 'h6',
    },
    largeBg: {
        color: 'grey',
        fontWeight: 'semibold',
        fontFamily: 'heading',
        fontSize: '220px',
        letterSpacing: '1px',
        opacity: 0.2,
        '@media screen and (max-width: 768px)': {
            fontSize: '90px',
        },
    },
    largeCopy: {
        color: 'grey',
        fontWeight: 'semibold',
        fontFamily: 'body',
        fontSize: 'xl',
        letterSpacing: '0px',
    },
    boldCopy: {
        color: 'grey',
        fontWeight: 'semibold',
        fontFamily: 'body',
        fontSize: 'lg',
        letterSpacing: '3px',
    },
    copy: {
        color: 'grey',
        fontFamily: 'body',
        fontSize: 'lg',
        // letterSpacing: '3px',
    },
    body: {
        fontWeight: 'regular',
        fontSize: 'body',
        color: 'grey',
        letterSpacing: '0.5px',
    },
    carDetailsCopy: {
        color: 'grey',
        fontFamily: 'body',
        fontSize: 'body',
        letterSpacing: '1px',
    },
    bigText: {
        color: 'grey',
        fontFamily: 'heading',
        fontWeight: 'semibold',
        fontSize: '116px',
        lineHeight: '111px',
        opacity: 0.2,
        '@media screen and (max-width: 576px)': {
            fontSize: '68px',
            lineHeight: '75px',
        },
    },
    bigTextMobile: {
        color: 'greyMedium',
        display: 'inline-block',
        fontFamily: 'heading',
        fontWeight: 'semibold',
        fontSize: '75px',
        lineHeight: '205px',
        opacity: .3,
    },
}

export default text
