import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    bigText: '',
    complete: false,
    characteristics: {
        chassisNo: '',
        engineNo: '',
        make: '',
        model: '',
        variant: '',
        year: '',
    },
    media: {},
    shortDescription: '',
    title: '',
    uid: '',
    vid: '',
}

export const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        setUserVehicle: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            const vehicle = JSON.parse(action.payload)

            state.bigText = vehicle.bigText
            state.characteristics = vehicle.characteristics
            state.complete = vehicle.complete
            state.media = vehicle.media
            state.shortDescription = vehicle.shortDescription
            state.title = vehicle.title
            state.uid = vehicle.uid
            state.vid = vehicle.vid
        },
        clearUserVehicle: (state) => initialState,
    },
})

// Action creators are generated for each case reducer function
export const {
    setUserVehicle,
    clearUserVehicle,
} = vehicleSlice.actions

export default vehicleSlice.reducer
