const colors = {
    aquaMedium: '#77B7BE',
    aquaLight: '#B6DBDF', // Used for hover effect on dropdown.
    aquaSuperLight: '#E4F1F2', // Used for Scrollbars
    black: '#101010',
    charcoal: '#1e1e1e',
    disabled: '#979797',
    errorDark: '#e96338', // Typically used for text
    errorLight: '#be7b77', // Typically used for outlines
    green: '#3DB960',
    grey: '#E0E0E0',
    greyMedium: '#828282',
    greyDark: 'rgb(42,42,42)',
    greyLight: 'rgba(255,255,255,0.1)',
    orange: '#F8784F',
    red: '#D54343',
    transparent: 'transparent',
    halfBlack: 'rgb(224,224,224)', // semi-transparent for borders
    white: '#fff',
}

export default colors
