import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    featured: '',
    media: '',
    title: '',
}

export const restorerSlice = createSlice({
    name: 'restorer',
    initialState,
    reducers: {
        setFeatured: (state, action) => {
            const featuredImage = action.payload
            state.featured= featuredImage
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setFeatured,
} = restorerSlice.actions

export default restorerSlice.reducer
