const buttons = {
    primary: {
        bg: 'charcoal',
        borderColor: 'aquaMedium',
        borderStyle: 'solid',
        borderRadius: 0,
        borderWidth: '1px',
        color: 'grey',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'semibold',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '148px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
        willChange: 'background-color',
        ':hover, :active': {
            bg: 'aquaMedium',
            color: 'white',
            borderColor: 'aquaMedium',
            transitionTimingFunction: 'ease-out',
            transitionProperty: 'background-color',
            transitionDuration: '200ms',
        }, '&.hasFocus:focus': {
            bg: 'aquaMedium',
            borderColor: 'white',
            borderStyle: 'dashed',
            color: 'white',
            outline: 'none',
        },
    },
    primary_alt: {
        bg: 'charcoal',
        borderColor: 'aquaMedium',
        borderStyle: 'solid',
        borderRadius: 0,
        borderWidth: '1px',
        color: 'grey',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'regular',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '192px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
        willChange: 'background-color, color',
        ':hover, :active': {
            bg: 'grey',
            color: 'white',
            borderColor: 'grey',
            transitionTimingFunction: 'ease-out',
            transitionProperty: 'background-color, color',
            transitionDuration: '200ms',
        }, ':focus': {
            bg: 'grey',
            borderColor: 'white',
            borderStyle: 'dashed',
            color: 'white',
            outline: 'none',
        },
        '@media screen and (max-width: 576px)': {
            minWidth: '148px',
        },
    },
    primary_grey: {
        bg: 'greyLight',
        borderColor: 'aquaMedium',
        borderStyle: 'solid',
        borderRadius: 0,
        borderWidth: '1px',
        color: 'grey',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'regular',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '192px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
        willChange: 'background-color',
        ':hover, :active': {
            bg: 'aquaMedium',
            color: 'white',
            borderColor: 'aquaMedium',
            transitionTimingFunction: 'ease-out',
            transitionProperty: 'background-color',
            transitionDuration: '200ms',
        },
        ':focus': {
            bg: 'aquaMedium',
            borderColor: 'white',
            borderStyle: 'dashed',
            color: 'white',
            outline: 'none',
        },
    },
    '@media screen and (max-width: 576px)': {
        minWidth: '148px',
    },
    quote: {
        bg: 'greyLight',
        color: 'grey',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'regular',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '132px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
        willChange: 'background-color, color',
        ':hover, :active': {
            bg: 'grey',
            color: 'black',
            transitionTimingFunction: 'ease-out',
            transitionProperty: 'background-color, color',
            transitionDuration: '200ms',
        }, ':focus': {
            bg: 'grey',
            color: 'black',
            outline: 'none',
        },
    },
    deadQuote: {
        bg: 'greyLight',
        color: 'grey',
        cursor: 'pointer',
        fontFamily: 'work-sans, Helvetica Neue, Helvetica, sans-serif',
        fontSize: 'md',
        fontWeight: 'regular',
        letterSpacing: 1,
        lineHeight: 'sm',
        minWidth: '132px',
        paddingTop: '10px',
        paddingRight: 'sm',
        paddingLeft: 'sm',
        paddingBottom: '10px',
        position: 'relative',
        textAlign: 'center',
    },
}

export default buttons
