import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filter: 'all',
    chassis: null,
    direction: 'desc',
}

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFeedFilter: (state, action) => {
            const { payload } = action
            state.filter = payload.filter
        },
        setFeedDirection: (state, action) => {
            const { payload } = action
            state.direction = payload.sort
        },
        clearFeedFilter: (state) => initialState,
        setChassisFilter: (state, action) => {
            const { payload } = action
            state.chassis = payload.chassis
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setFeedFilter,
    setFeedDirection,
    clearFeedFilter,
    setChassisFilter,
} = filterSlice.actions

export default filterSlice.reducer
