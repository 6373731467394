import { createGlobalStyle } from 'styled-components'
import theme from './Theme'

export const GlobalStyle = createGlobalStyle`    
    html, body {
        background-color: ${(props) => props.theme.colors.black};
        font-family: ${theme.fonts.body};
        font-size: ${theme.fontSizes.body};
        font-style: normal;
        font-weight: ${theme.fontWeights.regular};
        height: 100%;
        letter-spacing: 0;
        margin: 0;
        padding: 0;
        text-decoration: none;
    }

    img, svg {
        border: 0;
        /* https://web.dev/content-visibility/ */
        /* content-visibility: auto; */
        display: block;
        height: auto;
        max-width: 100%;
        width: 100%;
    }
    a {
        text-decoration: none;
    }

    .grecaptcha-badge {
        visibility: hidden;
    }

    .errorMessage {
        color: ${(props) => props.theme.colors.errorDark};
        display: block;
        margin-top: ${(props) => props.theme.space.xs};
    }
     // Why does this need to be not([type='hidden'])?
    input:not([type='hidden']),
    textarea:not([type='hidden']),
    select:not([type='hidden']) {
        autocomplete: off;
        border-color: ${(props) => props.theme.colors.aquaMedium};
        background-color: ${(props) => props.theme.colors.charcoal};
        color: ${(props) => props.theme.colors.grey};
        border-style: solid;
        border-width: 1px;
        font-family: ${(props) => props.theme.fonts.body};
        transition-duration: 300ms;
        transition-timing-function: ease-out;
        transition-property: border-color;
        will-change: border-color;
        :focus {
            border-color: ${(props) => props.theme.colors.aquaLight};
        }
        :first-child,
        :first-of-type {
            margin-top: 0;
        }
        ::placeholder {
            color:${(props) => props.theme.colors.grey}
        }
        ::-webkit-calendar-picker-indicator {
            filter: invert(1);
            cursor: pointer;
        }
    }
`
