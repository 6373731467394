import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoggingIn: false,
    loginError: false,
    isAuthenticated: false,
    unAuthorised: false,
    user: {},
    verifyingError: false,
    errors: '',
    userVerified: {},
    isVerifying: false,
    verificationMismatch: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        requestLogin: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.isLoggingIn = true
            state.loginError = false
        },
        receiveLogin: (state, action) => {
            state.isLoggingIn = false
            state.isAuthenticated = true
            state.unAuthorised = false
            state.userVerified = JSON.parse(action.payload)
            state.isVerifying = false
        },
        loginError: (state) => {
            state.isLoggingIn = false
            state.isAuthenticated = false
            state.loginError = true
            state.errors = 'Your email or password was incorrect.'
            state.isVerifying = false
        },
        requestLogout: (state) => {
            state.isLoggingOut = true
            state.logoutError = false
            state.isVerifying = false
        },
        receiveLogout: (state) => {
            state.isLoggingOut = false
            state.isAuthenticated = false
            state.user = {}
            state.userVerified = {}
            state.isVerifying = false
        },
        logoutError: (state) => {
            state.isLoggingOut = false
            state.logoutError = true
            state.isVerifying = false
        },
        verifyRequest: (state) => {
            state.isVerifying = true
            state.verifyingError = false
        },
        verifySuccess: (state) => {
            state.isAuthenticated = true
            state.isVerifying = false
        },
        setAuthUser: (state, action) => {
            state.user = JSON.parse(action.payload)
        },
        unAuthorisedAccess: (state) => {
            state.unAuthorised = true
        },
        passwordMismatch: (state, action) => {
            state.verificationMismatch = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    requestLogin,
    receiveLogin,
    loginError,
    requestLogout,
    receiveLogout,
    logoutError,
    verifyRequest,
    verifySuccess,
    setAuthUser,
    unAuthorisedAccess,
    passwordMismatch,
} = authSlice.actions

export default authSlice.reducer
