import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    galleryIsOpen: false,
    addIsOpen: false,
    editIsOpen: false,
    uploadIsOpen: false,
    uploadCategory: '',
    isEdit: false,
    add: {
        vid: '',
        tid: '',
    },
    item: {},
    edit:
    { node: {},
    },
    confirm: '',
    featured: '',
    id: '',
    media: '',
    title: '',
    vid: '',
    refresh: false,
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // {immutable} state based off those changes
            const { media, title, vid } = action.payload
            state.isOpen = true
            state.media = media
            state.title = title
            state.vid = vid
        },
        openGalleryModal: (state, action) => {
            const { media, title, vid } = action.payload
            state.galleryIsOpen = true
            state.media = media
            state.title = title
            state.vid = vid
        },
        openAddModal: (state, action) => {
            const { payload } = action
            state.addIsOpen = true
            state.media = payload
        },
        openEditModal: (state, action) => {
            const { payload } = action
            state.addIsOpen = true
            state.isEdit = true
            state.edit.node = payload
        },
        closeModal: (state) => initialState,
        refreshTimeline: (state, action) => {
            state.refresh= !state.refresh
        },
        setFeatured: (state, action) => {
            const featuredImage = action.payload
            state.featured= featuredImage
        },
        openConfirmModal: (state, action) => {
            const options = action.payload
            state.isOpen = true
            state.confirm = options.confirm
            state.item = options.item
            state.id = options.id
        },
        openDeleteModal: (state, action) => {
            const item = action.payload
            state.isOpen = true
            state.item = item
        },
        openUploadModal: (state, action) => {
            const { payload } = action
            state.uploadIsOpen = true
            state.uploadCategory = payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    openModal,
    openGalleryModal,
    openAddModal,
    openEditModal,
    closeModal,
    setFeatured,
    openConfirmModal,
    openDeleteModal,
    openUploadModal,
    refreshTimeline,
} = modalSlice.actions

export default modalSlice.reducer
