import breakpoints from './breakpoints'
import buttons from './buttons'
import colors from './colors'
import fonts from './fonts'
import fontSizes from './fontSizes'
import fontWeights from './fontWeights'
import space from './space'
import text from './text'
import variants from './variants'

export default {
    breakpoints,
    buttons,
    colors,
    fonts,
    fontSizes,
    fontWeights,
    space,
    text,
    variants,
}
