import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    about: '',
    address: {
        line1: '',
        line2: '',
        city: '',
        country: '',
    },
    email: '',
    media: {
        images: [],
    },
    name: '',
    id: '',
    tel_no: '',
    url: '',
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setAccountDetails: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            const { about, address, email, media, name, tel_no, url, id } = JSON.parse(action.payload)
            state.about = about
            state.address = address
            state.email = email
            state.id = id
            state.media = media
            state.name = name
            state.tel_no = tel_no
            state.url = url
        },
        clearAccountDetails: (state) => initialState,
        removeAccountMedia: (state, action) => {
            state.media.images = action.payload
        },
        setAccountMedia: (state, action) => {
            state.media.images = [...state.media.images, ...action.payload]
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setAccountDetails,
    removeAccountMedia,
    clearAccountDetails,
    setAccountMedia,
} = accountSlice.actions

export default accountSlice.reducer
