const variants = {
    link: {},
    container: {
        maxWidth: '1440px',
        width: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}

export default variants
