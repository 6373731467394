import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    media: {
        images: [],
        docs: [],
    },
}

export const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        clearUploadMedia: (state) => {
            state.media = { images: [],
                docs: []}
        },
        setVehicleImages: (state, action) => {
            state.media.images = action.payload
        },
        setMultiDocMedia: (state, action) => {
            state.media.docs = action.payload
        },
        setMultiImageMedia: (state, action) => {
            state.media.images = action.payload
        },
        setMultiMedia: (state, action) => {
            state.media = action.payload
        },
        setDocMedia: (state, action) => {
            state.media.docs = [...state.media.docs, ...action.payload]
        },
        setImageMedia: (state, action) => {
            state.media.images = [...state.media.images, ...action.payload]
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    clearUploadMedia,
    setVehicleImages,
    setDocMedia,
    setMultiDocMedia,
    setMultiImageMedia,
    setImageMedia,
    setMultiMedia,
} = uploadSlice.actions

export default uploadSlice.reducer
